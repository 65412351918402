import React, { FC } from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';

import topicSpaceLogo from '../../assets/topicspace-logo.jpg';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 40px;
  height: auto;
  display: inline-block;
`;

const MarginlessBox = styled(Box)`
  && {
    margin: 0;
  }
`;

const StyledBox = styled(Box)`
  height: 85vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const VerticallyCenteredContainer = styled.div`
  display: flex;
  height: 85vh;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    textAlign: 'center',
  },
  body2: {
    fontSize: 'var(--body-lg-font-size)',
    lineHeight: 'var(--body-lg-line-height)',
    letterSpacing: 'var(--body-lg-letter-spacing)',
    opacity: 0.4,
  },
}));

const StyledBody = styled.main`
  fill: currentcolor;
  line-height: 1.5;
  font-family: inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
  background: #ffffff
  color: rgb(4, 4, 4);
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
`;

const Section = styled.section`
  width: 100%;
  overflow: visible;
`;

const InnerSection = styled.div`
  width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

export const Block = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const Title = styled.h1`
  margin-left: 0.4rem;
  text-align: center;
  color: var(--color-black-08);
  font-size: var(--heading-md-font-size);
  font-weight: var(--heading-md-font-weight);
  line-height: var(--heading-md-line-height);
  letter-spacing: var(--heading-md-letter-spacing);
`;

const SubTitle = styled.h2`
  margin-top: 2vh;
  margin-bottom: 3vh;
  text-align: center;
  color: var(--color-black-08);
  font-size: var(--heading-sm-font-size);
  font-weight: var(--heading-sm-font-weight);
  line-height: 1.5;
  letter-spacing: 0.05;
`;

const SubSubTitle = styled.h3`
  margin-top: 2vh;
  margin-bottom: 3vh;
  text-align: center;
  color: var(--color-black-07);
  font-size: var(--heading-xs-font-size);
  font-weight: var(--heading-xs-font-weight);
  line-height: 1.5;
  letter-spacing: 0.05;
`;

const Body = styled.h3`
  color: var(--color-black-07);
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.5;
  letter-spacing: 0.05;
`;

const AppBody: FC = () => {
  const classes = useStyles();
  const iOSUrl = 'https://apps.apple.com/us/app/topicspace/id1553582496';

  return (
    <>
      <StyledBox display={{ xs: 'block', sm: 'none' }}>
        <VerticallyCenteredContainer>
          <div className={classes.root}>
            <div
              style={{
                flexDirection: 'row',
                flex: 1,
                marginBottom: 25,
              }}
            >
              <LogoContainer>
                <Icon src={topicSpaceLogo} className="AuthScreen-icon" />
                <MarginlessBox display={{ xs: 'block' }}>
                  <Title>TopicSpace</Title>
                </MarginlessBox>
              </LogoContainer>
            </div>

            <SubTitle className="LandingScreen-title">
              A global community of experts and learners who come together each
              day to democratize knowledge.
            </SubTitle>
            <SubSubTitle className="LandingScreen-title">
              We&apos;re releasing features at light speed and working hard to
              support more users.
            </SubSubTitle>
            <Body>Download the app today and say hi.</Body>

            <div style={{ marginTop: 25 }}>
              <MobileStoreButton
                store="ios"
                url={iOSUrl}
                linkProps={{ title: 'iOS Store Button' }}
                width={130}
              />
            </div>
          </div>
        </VerticallyCenteredContainer>
      </StyledBox>
      <StyledBox display={{ xs: 'none', sm: 'block' }}>
        <StyledBody>
          <Section>
            <InnerSection>
              <VerticallyCenteredContainer className="LandingScreen-screen">
                <div className={classes.root}>
                  <div
                    style={{
                      flexDirection: 'row',
                      flex: 1,
                      marginBottom: 25,
                    }}
                  >
                    <LogoContainer>
                      <Icon src={topicSpaceLogo} className="AuthScreen-icon" />
                      <MarginlessBox display={{ xs: 'block' }}>
                        <Title>TopicSpace</Title>
                      </MarginlessBox>
                    </LogoContainer>
                  </div>

                  <SubTitle className="LandingScreen-title">
                    A global community of experts and learners who come together
                    each day to democratize knowledge.
                  </SubTitle>
                  <SubSubTitle className="LandingScreen-title">
                    We&apos;re releasing features at light speed and working
                    hard to support more users in the future.
                  </SubSubTitle>
                  <Body>Download the app today and say hi.</Body>

                  <div style={{ marginTop: 20 }}>
                    <MobileStoreButton
                      store="ios"
                      url={iOSUrl}
                      linkProps={{ title: 'iOS Store Button' }}
                      width={130}
                    />
                  </div>
                </div>
              </VerticallyCenteredContainer>
            </InnerSection>
          </Section>
        </StyledBody>
      </StyledBox>
    </>
  );
};

export default AppBody;

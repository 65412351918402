import { ConnectedRouter } from 'connected-react-router';
/* eslint react/jsx-props-no-spreading: off */
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import routes from './utils/routes.json';
import App from './components/App';
import GlobalStyles from './global.styles';
import { configuredStore, history } from './redux/store';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#6CA59B' },
    secondary: { main: '#F5D427' },
  },
  typography: {
    fontFamily: [
      'inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    // Applied to the menu item text inside <li> elements
    MuiTypography: {
      body1: {
        fontSize: 'var(--heading-sm-font-size)',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 'var(--heading-sm-font-size)',
      },
    },
  },
});

export default function Routes() {
  const store = configuredStore();

  return (
    <MuiThemeProvider theme={theme}>
      <GlobalStyles />
      <Provider store={store}>
        {/* Context provider */}
        <ConnectedRouter history={history}>
          <Route
            path={[
              routes.HOME,
              routes.LANDING,
              routes.OPPORTUNITIES,
              routes.COMPANIES,
              routes.AUTH,
              routes.DASHBOARD,
            ]}
            component={App}
          />
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  );
}

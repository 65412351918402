import React, { FC } from 'react';

import styled from 'styled-components';
import AppFooter from './AppFooter';
import AppBody from './AppBody';

const Container = styled.div`
  overflow-x: hidden;
  box-sizing: border-box;
  background: #ffffff;
  justify-content: center;
  align-items: center;
`;

const App: FC = () => {
  return (
    <Container>
      <AppBody />
      <AppFooter />
    </Container>
  );
};

export default App;

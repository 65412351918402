import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';

const StyledBox = styled(Box)`
  width: 100%;
  height: 15vh;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

const VerticallyCenteredContainer = styled.div`
  display: flex;
  width: 100%;
  height: 15vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MarginlessBox = styled(Box)`
  && {
    margin: 0;
    display: flex;
    justify-content: center;
    aligh-items: center;
  }
`;

const StyledAnchor = styled.a`
  color: #2f80ed;
  text-decoration: none;
  font-size: var(--body-sm-font-size);
  font-weight: var(--body-md-font-weight);
  line-height: var(--body-md-line-height);
  letter-spacing: var(--body-md-letter-spacing);
`;

const StyledText = styled.p`
  cursor: pointer;
  color: #2f80ed;
  text-decoration: none;
  font-size: var(--body-sm-font-size);
  font-weight: var(--body-md-font-weight);
  line-height: var(--body-md-line-height);
  letter-spacing: var(--body-md-letter-spacing);
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    textAlign: 'center',
  },
  body2: {
    fontSize: 'var(--body-lg-font-size)',
    lineHeight: 'var(--body-lg-line-height)',
    letterSpacing: 'var(--body-lg-letter-spacing)',
    opacity: 0.4,
  },
}));

const StyledButton = styled(Button)`
  && {
    min-width: 0px;
    text-transform: none;
  }
`;

const AppFooter = (): JSX.Element => {
  const handleContact = () => {
    window.location.href = 'mailto:jason@topicspace.app';
  };
  const classes = useStyles();

  return (
    <StyledBox display={{ xs: 'block' }}>
      <VerticallyCenteredContainer>
        <div className={classes.root}>
          <MarginlessBox display={{ xs: 'block' }}>
            <StyledButton>
              <StyledAnchor
                href="https://www.linkedin.com/company/topicspace/"
                rel="noreferrer"
              >
                About
              </StyledAnchor>
            </StyledButton>
            <Divider orientation="vertical" flexItem light />

            <StyledButton>
              <StyledAnchor
                href="https://www.notion.so/guidelyte/TopicSpace-HQ-f3bd6e7a591744f5a3418bcf419c563c"
                rel="noreferrer"
              >
                FAQ
              </StyledAnchor>
            </StyledButton>
            <Divider orientation="vertical" flexItem />

            <StyledButton onClick={handleContact}>
              <StyledText>Contact</StyledText>
            </StyledButton>
            <Divider orientation="vertical" flexItem />

            <StyledButton>
              <StyledAnchor
                href="https://www.notion.so/guidelyte/Terms-of-Use-d684ad9b7c3546a089ee2f4773d72312"
                rel="noreferrer"
              >
                Terms
              </StyledAnchor>
            </StyledButton>
            <Divider orientation="vertical" flexItem />

            <StyledButton>
              <StyledAnchor
                href="https://www.notion.so/guidelyte/Privacy-Policy-fc6b2b3419fc4407a7280d3832813e5f"
                rel="noreferrer"
              >
                Privacy
              </StyledAnchor>
            </StyledButton>
          </MarginlessBox>
        </div>
      </VerticallyCenteredContainer>
    </StyledBox>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(AppFooter);
